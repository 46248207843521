import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useProductStore = defineStore("products", () => {
  const data = ref<Product[]>([]);

  // TODO: adicionar lógica quando o sistema tiver novos produtos
  const currentProduct = computed(() => data.value[0])

  function setProducts(products: Product[]) {
    data.value = products;
  }

  return {
    data,
    currentProduct,
    setProducts,
  };
});
